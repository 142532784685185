.table_row {
    height: 30px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 16px;
    color: #707070;
    font-family: Roboto;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table_row:hover {
    cursor: pointer;
}

.table_row_expanded {
    height: 30px;
    background-color: #707070;
    font-size: 16px;
    color: #FFFFFF;
    font-family: Roboto;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table_row_expanded:hover {
    cursor: pointer;
}

.table_row_cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table_row_text_cell > p {
    width: fit-content;
}
.table_row_text_cell > p:hover {
    cursor: text;
}

.table_row_cell:not(:last-child) {
    padding-right: 12px;
}

.table_row_cell:is(:first-child) {
    padding-left: 12px;
}

.expand_row {
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.expand_row.expanded {
    opacity: 1;
    background-clip: padding-box;
    padding-left: 2px;
    padding-right: 2px;
    /*border-left: 2px solid rgb(112, 112, 112);
    border-right: 2px solid rgb(112, 112, 112);*/
}

.expanded_row_toolbar {
    background-color: #CCCCCC;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    padding: 0 14px;
}

.version-opener:hover {
    cursor: pointer;
}

.labels_cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
}

.label_cell_square {
    width: 12px;
    height: 12px;
    margin-right: 4px;
}

.status_cell {
    font-size: 16px;
    font-family: Roboto;
}

.canceled_session_expanded {
    color: #ffc5c5;
}

.canceled_session {
    color: #F80534;
}

.deleted_session {
    color: #BFBFBF;
}

.error_session_expanded {
    color: #ffc5c5;
}

.error_session {
    color: #F80534;
}

.finished_session {
    color: #2B8DFF;
}

.finished_session_expanded {
    color: #d2e6fc;
}

.initializing_session {
    color: #606060;
}

.in_progress_session {
    color: #000000;
}

.in_queue_session {
    color: #404040;
}

.detail_title {
    font-size: 12px;
    color: #707070;
    font-weight: bold;
    font-family: Roboto;
}

.preview-contents {
    background: #D9D9D9;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    width: 95%;
    padding: 6px 9px;
}

.preview-contents-text {
    color: #707070;
    font-family: Roboto Mono;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    white-space: normal;
    margin: 0;
}

.manage-labels-button {
    background: url('../../../../public/add_more.svg') no-repeat center;
    height: 24px !important;
    width: 24px !important;
    margin-right: 5px;
}
.cancel-session-button {
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.recreate-button {
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.download-button {
    background: url('../../../../public/download.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.share-button {
    background: url('../../../../public/share.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.copy-button {
    background: url('../../../../public/content_copy.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.delete-button {
    background: url('../../../../public/delete.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}