.header-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-title-wrapper-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.header-subtitle {
  padding: 5px;
  border-radius: 15px;
  border: 1px solid #db503d;
  color: #db503d;
  margin-left: 15px;
  font-size: 12px;
  box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
}

.header-title {
  font-family: 'Roboto';
  font-size: 56px;
  color: #2b8dff;
  font-weight: 300;
  user-select: none;
}

.header-wrapper-dashboard {
  box-shadow: rgb(204, 204, 204) 0px 3px 10px;
  background-color: rgb(242, 242, 242);
  padding-left: 56px;
  padding-right: 56px;
  z-index: 9999;
}
